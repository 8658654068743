// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Golos+Text&family=Hanken+Grotesk:wght@300&family=Playfair+Display+SC&family=Playfair+Display:wght@500&family=Playfair:opsz,wght@5..1200,500&family=Roboto+Slab:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    padding: 0;
    font-family: 'Hanken Grotesk', sans;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,mCAAmC;AACvC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Golos+Text&family=Hanken+Grotesk:wght@300&family=Playfair+Display+SC&family=Playfair+Display:wght@500&family=Playfair:opsz,wght@5..1200,500&family=Roboto+Slab:wght@300&display=swap');\n\n* {\n    padding: 0;\n    font-family: 'Hanken Grotesk', sans;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
